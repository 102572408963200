<template>
  <b-row>
    <b-col cols="12">
      <b-card class="my-card m-0">
        <b-table
          striped
          small
          id="myTables"
          bordered
          selectable
          selected-variant="primary"
          select-mode="single"
          responsive="sm"
          @row-contextmenu="handleContextMenuEvent"
          @row-clicked="clicked"
          @row-dblclicked="editForm"
          :items="tariffClients"
          :fields="fields"
          head-row-variant="secondary"
        >
          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
            </b-form-checkbox>
          </template>
          <template #cell(property)="data">
            <span v-for="(property, index) in data.item.property" :key="index">
              {{ property }}
            </span>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{
                data.item.created_at.substring(
                  0,
                  data.item.created_at.indexOf("T")
                ) +
                " " +
                data.item.created_at.substring(
                  data.item.created_at.indexOf("T") + 1,
                  data.item.created_at.indexOf(".")
                )
              }}
            </span>
          </template>
          <template #cell(updated_at)="data">
            <span>
              {{
                data.item.updated_at.substring(
                  0,
                  data.item.updated_at.indexOf("T")
                ) +
                " " +
                data.item.updated_at.substring(
                  data.item.updated_at.indexOf("T") + 1,
                  data.item.updated_at.indexOf(".")
                )
              }}
            </span>
          </template>
        </b-table>
        <vue-context ref="menu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="deleteAllowance()"
            >
              <feather-icon icon="EditIcon" size="16" />
              <span class="ml-75">Удалить</span>
            </b-link>
          </li>
        </vue-context>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueContext from "vue-context";
export default {
  components: { VueContext },
  props: ["tariffClients"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "50px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "50px" } },
        {
          key: "allowance_id",
          label: "Id надбавок",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "user_id",
          label: "Id пользователя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "property",
          label: "Cвойство",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Создан",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "updated_at",
          label: "Обработан",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      id: "",
      index: null,
    };
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    clicked(item, index, event) {
      this.id = item.id;
      this.$emit("getId", this.id);
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }
      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.textContent.trim();
      cellSelect();
    },
    editForm(id) {
      this.$emit("editForm", id);
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.index = item.id;
    },
    deleteAllowance() {
      this.$http
        .delete(`allowance-client/${this.index}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    resizeable();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
</style>
